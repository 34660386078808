import React from 'react';
import { Box, 
    Link,
    Paper,
    AppBar, 
    Container, 
    Toolbar, 
    Button,
    makeStyles,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';
import cyan from '@material-ui/core/colors/cyan';

import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { inspidate } from '../../blocks/landingpage/src/assets';
import DrawerComponent from './Drawer.web';
import "./Header.css"


const useStyles = makeStyles({
    logo:{
        height: ""
    }
  });

export default function Header(props:any) {
    const barBg = cyan[50];
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
        <Paper variant='outlined'>
            <AppBar position="relative" elevation={0} style={{ backgroundColor: props.bgColor || barBg, padding: '10px 0'}}>
                <Container maxWidth="lg">
                    <Toolbar  style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                            <Box mr={3}>
                                <img src={inspidate} className="logo" alt="Inspidate"  />
                            </Box>
                            {
                                !isMobile && <Box>
                                                <Button color="primary" href='/BrowseMentorPage'  startIcon={<GroupOutlinedIcon />} variant="outlined" > Browse Mentors</Button>
                                            </Box> 
                            }
                            
                        </Box>
                        {isMobile ? (
                        <DrawerComponent />
                        ) : (
                        <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                            <Box mr={3}>
                                <Link color="secondary" href="/EmailAccountLoginBlock">Log In</Link>
                            </Box>
                            <Box>
                                <Button color="primary"  endIcon={<ArrowRightAltIcon />} style={{color: 'white'}} variant="contained" disableElevation> Get Started</Button>
                            </Box>
                        </Box>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
        </Paper>
  );
}
