import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Box, Link, Button, InputBase} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { inspidate } from '../../blocks/landingpage/src/assets';
import "./SearchBox.css"

const useStyles = makeStyles({
 
  icon: {
    height: "24px",
    width: "24px",
    padding: "10px",
    flexShrink: 0,
  },
  button: {
    borderRadius: 10,
    padding: '15px 60px',
    color: 'white',
    background: "#212121",
    flexShrink: 0,
    fontSize: "18px",
    '&:hover': {
      backgroundColor: '#212121',
      borderColor: '#212121',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#212121',
      borderColor: '#212121',
    },
  },
});

export default function SearchBox(props:any) {
  const classes = useStyles();
  return (
    <Box>
      <form className="search_root">   
          <div style={{display: 'flex', alignItems:'center', justifyItems: 'start' }}>
            <svg aria-hidden="true" className={classes.icon} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            <InputBase type="search" id="default-search"  placeholder="Find mentors around the globe " required />
          </div>
          <Button variant="contained" className={`${classes.button} search_button`}  size="large" disableElevation>
            Search
          </Button>
      </form>
    </Box>
  );
}
