// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import CompleteProfileBlock from "../../blocks/CustomisableUserProfiles/src/CompleteProfileBlock";
import UserMyProfile from "../../blocks/CustomisableUserProfiles/src/UserMyProfile";
import UserMyProfileSettings from "../../blocks/CustomisableUserProfiles/src/UserMyProfileSettings";
import BecomeMentor from "../../blocks/CustomisableUserProfiles/src/BecomeMentor";
import UserFavourites from "../../blocks/CustomisableUserProfiles/src/UserFavourites.web";
import BankDetails from "../../blocks/CustomisableUserProfiles/src/BankDetails.web";
import PricingAndMedia from "../../blocks/CustomisableUserProfiles/src/PricingAndMedia.web";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import './App.css'

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlockWeb.web'
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import CallRecording2 from "../../blocks/CallRecording2/src/CallRecording2";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Payments from "../../blocks/Payments/src/Payments";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Videos from "../../blocks/videos/src/Videos";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import Customform from "../../blocks/customform/src/Customform";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import ResetPasswordLinkSent from "../../blocks/forgot-password/src/ResetPasswordLinkSent.web";
import Notifications from "../../blocks/notifications/src/Notifications";
import Analytics from "../../blocks/analytics/src/Analytics";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import DesktopNotifications from "../../blocks/desktopnotifications/src/DesktopNotifications";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import VideoConversation from "../../blocks/VideoConversation/src/VideoConversation";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import Share from "../../blocks/share/src/Share";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Sorting from "../../blocks/sorting/src/Sorting";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import  EmailConfirmation from "../../blocks/email-account-registration/src/EmailConfirmation.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlockWeb.web";
import LinkedinRedirectBlockWeb from "../../blocks/email-account-login/src/LinkedinRedirectBlockWeb.web";
import BrowseMentorPage from "../../blocks/landingpage/src/BrowseMentorPage.web";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import MentorProfilePage from "../../blocks/landingpage/src/MentorProfilePage.web";

import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";

const routeMap = {
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
CallRecording2:{
 component:CallRecording2,
path:"/CallRecording2"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Payments:{
 component:Payments,
path:"/Payments"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Videos:{
 component:Videos,
path:"/Videos"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
Customform:{
 component:Customform,
path:"/Customform"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
ResetPasswordLinkSent:{
  component:ResetPasswordLinkSent,
 path:"/ResetPasswordLinkSent"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
DesktopNotifications:{
 component:DesktopNotifications,
path:"/DesktopNotifications"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
VideoConversation:{
 component:VideoConversation,
path:"/VideoConversation"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ReviewAndApproval:{
 component:ReviewAndApproval,
path:"/ReviewAndApproval"},
Share:{
 component:Share,
path:"/Share"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailConfirmation:{
  component:EmailConfirmation,
 path:"/EmailConfirmation"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LinkedinRedirectBlockWeb:{
  component:LinkedinRedirectBlockWeb,
 path:"/LinkedinRedirectBlockWeb"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
MentorProfilePage:{
  component:MentorProfilePage,
 path:"/MentorProfilePage"},
BrowseMentorPage:{
  component:BrowseMentorPage,
 path:"/BrowseMentorPage"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
CompleteProfileBlock:{
  component:CompleteProfileBlock,
 path:"/CompleteProfile"},
 UserMyProfile:{
  component:UserMyProfile,
 path:"/UserMyProfile"},
 UserMyProfileSettings:{
  component:UserMyProfileSettings,
 path:"/UserMyProfileSettings"},
 BecomeMentor:{
  component:BecomeMentor,
 path:"/BecomeMentor"},
 UserFavourites:{
  component:UserFavourites,
 path:"/UserFavourites"},
 BankDetails:{
  component:BankDetails,
 path:"/BankDetails"},
 PricingAndMedia:{
  component:PricingAndMedia,
 path:"/PricingAndMedia"}, 
 Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};


// Custom theme begins here
const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#00aeef'
    },
    secondary: {
      main: '#4D4D4D'
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      color:'white',
      padding: '10px 20px'
    },
  },
});
// Custom theme ends here


const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        <ThemeProvider theme={theme}>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        </ThemeProvider>
      </View>
    );
  }
}

export default App;