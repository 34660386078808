import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
  Tabs, 
  Tab,
  Chip,
  Grid,
  TextField
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from "@material-ui/icons/Add";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import InboxIcon from "@material-ui/icons/Inbox";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { avatar, elogo, bag } from "./assets";
import "./CustomisableUserProfiles.css"

// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
} from "./CustomisableUserProfilesController";
import Header from "../../../components/src/Header.web";
import { calendar, mentor1, mentor2, v1, v2, v3, video } from "../../landingpage/src/assets";
import MentorVideoCard from "../../../components/src/MentorVideosCard.web";
// Custom theme begins here
const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
      color:'white',
      padding: '10px 20px'
    },
  },
});
// Custom theme ends here
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const industries = [
  'Industry1',
  'Industry2',
  'Industry3',
  'Industry4',
  'Industry5',
];
const interests = [
  'UI/UX',
  'Copywriting',
  'Frontend Development',
  'Backend Development',
  'Fullstack Development',
];
// Custom style begins here
const styles = {
  button: {
    borderRadius: 10,
    padding: '15px 60px',
  },
  tabs: {
    borderBottom: "1px solid rgb(77 77 77 / 17%)" 
  },
  tab:{
    minWidth: "auto",
    fontSize: "16px"
  },
  inputs: {
    borderRadius: 10,
  },
};
// Custom style ends here


export default class UserFavourites extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <div style={{backgroundColor: "rgb(250, 250, 250)", overflowX: 'hidden'}}>
        <Header  bgColor="white" />
        <Container maxWidth="lg" style={{margin: "40px auto"}}>
            <Box>
                <Typography variant="h5" component="h2" style={{fontWeight:'bold', margin: "20px 0"}}>
                  Favourites (2)
                </Typography>
                <Box>
                  <Tabs indicatorColor="primary" style={{...styles.tabs}} textColor="primary" value={this.state.tabs} onChange={this.handleTabChange} aria-label="simple tabs example">
                    <Tab style={{...styles.tab}} label="Mentors" {...a11yProps(0)} />
                    <Tab style={{...styles.tab}} label="Latest Videos" {...a11yProps(1)} />
                  </Tabs>
                    <Box role="tabpanel"
                        hidden={this.state.tabs !== 0}
                        id={`simple-tabpanel-${0}`}
                        aria-labelledby={`simple-tab-${0}`}>
                      {/* Mentor Card Begins*/}
                      <Box p={3} mt={3} className={"bg-white flex-between shadow-lg inputRadius"}>
                        <Box className="flex-start ">
                          <img src={ mentor1 } style={{borderRadius: "100%"}} alt="Jellina Hawking " />
                          <Box ml={3}>
                            <Box className={"flex-start"}>
                              <Typography component="h3" variant='h5' style={{fontWeight: 700, color: "#4D4D4D"}}>
                                Jellina Hawking 
                              </Typography>
                            </Box>
                            <Typography component="h5" variant='body1' style={{margin: "10px 0", color: "#818181"}}>
                              Google Data Studio Expert, Comp Intel Pro. Startups Acquired
                            </Typography> 
                            <Box className={"flex-start"}>
                              <Box className={"flex-start"}>
                                <img src={ calendar } className={"icons"} alt="Calendar" />
                                <Typography component="h5" variant='body1' style={{marginLeft: "10px", color: "#818181", fontWeight: 500}}>
                                  Next available - Friday, March 18, 2022 1:30 AM IST
                                </Typography> 
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="flex-items-end">
                          <Button variant="contained" size="large" className="" style={{color:"red", background:"white"}}  startIcon={<DeleteIcon />} disableElevation>
                          Remove
                        </Button>
                        <Button variant="contained" color='primary' classes={{root: 'buttons'}}  size="large" disableElevation>
                          Request a Call
                        </Button>
                        </Box>
                      </Box>
                      {/* Mentor Card Ends*/}
                      {/* Mentor Card Begins*/}
                      <Box p={3} mt={3} className={"bg-white flex-between shadow-lg inputRadius"}>
                        <Box className="flex-start ">
                          <img src={ mentor1 } style={{borderRadius: "100%"}} alt="Jellina Hawking " />
                          <Box ml={3}>
                            <Box className={"flex-start"}>
                              <Typography component="h3" variant='h5' style={{fontWeight: 700, color: "#4D4D4D"}}>
                                Jellina Hawking 
                              </Typography>
                            </Box>
                            <Typography component="h5" variant='body1' style={{margin: "10px 0", color: "#818181"}}>
                              Google Data Studio Expert, Comp Intel Pro. Startups Acquired
                            </Typography> 
                            <Box className={"flex-start"}>
                              <Box className={"flex-start"}>
                                <img src={ calendar } className={"icons"} alt="Calendar" />
                                <Typography component="h5" variant='body1' style={{marginLeft: "10px", color: "#818181", fontWeight: 500}}>
                                  Next available - Friday, March 18, 2022 1:30 AM IST
                                </Typography> 
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="flex-items-end">
                          <Button variant="contained" size="large" className="" style={{color:"red", background:"white"}}  startIcon={<DeleteIcon />} disableElevation>
                          Remove
                        </Button>
                        <Button variant="contained" color='primary' classes={{root: 'buttons'}}  size="large" disableElevation>
                          Request a Call
                        </Button>
                        </Box>
                      </Box>
                      {/* Mentor Card Ends*/}
                      {/* Mentor Card Begins*/}
                      <Box p={3} mt={3} className={"bg-white flex-between shadow-lg inputRadius"}>
                        <Box className="flex-start ">
                          <img src={ mentor1 } style={{borderRadius: "100%"}} alt="Jellina Hawking " />
                          <Box ml={3}>
                            <Box className={"flex-start"}>
                              <Typography component="h3" variant='h5' style={{fontWeight: 700, color: "#4D4D4D"}}>
                                Jellina Hawking 
                              </Typography>
                            </Box>
                            <Typography component="h5" variant='body1' style={{margin: "10px 0", color: "#818181"}}>
                              Google Data Studio Expert, Comp Intel Pro. Startups Acquired
                            </Typography> 
                            <Box className={"flex-start"}>
                              <Box className={"flex-start"}>
                                <img src={ calendar } className={"icons"} alt="Calendar" />
                                <Typography component="h5" variant='body1' style={{marginLeft: "10px", color: "#818181", fontWeight: 500}}>
                                  Next available - Friday, March 18, 2022 1:30 AM IST
                                </Typography> 
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="flex-items-end">
                          <Button variant="contained" size="large" className="" style={{color:"red", background:"white"}}  startIcon={<DeleteIcon />} disableElevation>
                          Remove
                        </Button>
                        <Button variant="contained" color='primary' classes={{root: 'buttons'}}  size="large" disableElevation>
                          Request a Call
                        </Button>
                        </Box>
                      </Box>
                      {/* Mentor Card Ends*/}
                      {/* Mentor Card Begins*/}
                      <Box p={3} mt={3} className={"bg-white flex-between shadow-lg inputRadius"}>
                        <Box className="flex-start ">
                          <img src={ mentor1 } style={{borderRadius: "100%"}} alt="Jellina Hawking " />
                          <Box ml={3}>
                            <Box className={"flex-start"}>
                              <Typography component="h3" variant='h5' style={{fontWeight: 700, color: "#4D4D4D"}}>
                                Jellina Hawking 
                              </Typography>
                            </Box>
                            <Typography component="h5" variant='body1' style={{margin: "10px 0", color: "#818181"}}>
                              Google Data Studio Expert, Comp Intel Pro. Startups Acquired
                            </Typography> 
                            <Box className={"flex-start"}>
                              <Box className={"flex-start"}>
                                <img src={ calendar } className={"icons"} alt="Calendar" />
                                <Typography component="h5" variant='body1' style={{marginLeft: "10px", color: "#818181", fontWeight: 500}}>
                                  Next available - Friday, March 18, 2022 1:30 AM IST
                                </Typography> 
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="flex-items-end">
                          <Button variant="contained" size="large" className="" style={{color:"red", background:"white"}}  startIcon={<DeleteIcon />} disableElevation>
                          Remove
                        </Button>
                        <Button variant="contained" color='primary' classes={{root: 'buttons'}}  size="large" disableElevation>
                          Request a Call
                        </Button>
                        </Box>
                      </Box>
                      {/* Mentor Card Ends*/}
                    </Box>
                    <Box role="tabpanel"
                        hidden={this.state.tabs !== 1}
                        id={`simple-tabpanel-${1}`}
                        aria-labelledby={`simple-tab-${1}`}>
                          <Box p={3} mt={3} className={"bg-white flex-between shadow-lg inputRadius"} >
                              <Grid  container spacing={4}>
                                <Grid item xs={12} md={4}>
                                  <Box width={"100%"}>
                                    <video style={{width: "100%", height: "auto", borderRadius: 10}} poster={v1} controls>
                                      <source src={video} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                    <Box mt={1} className="flex-start">
                                        <div className="avatar-container">
                                          <img src={mentor1} className="image-avatar" alt="" />
                                        </div>
                                          <h3 className="mentor-name">Stellina Parker</h3>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Box width={"100%"}>
                                    <video style={{width: "100%", height: "auto", borderRadius: 10}} poster={v2} controls>
                                      <source src={video} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                    <Box mt={1} className="flex-start">
                                        <div className="avatar-container">
                                          <img src={mentor1} className="image-avatar" alt="" />
                                        </div>
                                          <h3 className="mentor-name">Jennifer Lopez</h3>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Box width={"100%"}>
                                    <video style={{width: "100%", height: "auto", borderRadius: 10}} poster={v3} controls>
                                      <source src={video} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                    <Box mt={1} className="flex-start">
                                        <div className="avatar-container">
                                          <img src={mentor1} className="image-avatar" alt="" />
                                        </div>
                                          <h3 className="mentor-name">Stellina Parker</h3>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Box width={"100%"}>
                                    <video style={{width: "100%", height: "auto", borderRadius: 10}} poster={v1} controls>
                                      <source src={video} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                    <Box mt={1} className="flex-start">
                                        <div className="avatar-container">
                                          <img src={mentor1} className="image-avatar" alt="" />
                                        </div>
                                          <h3 className="mentor-name">Stellina Parker</h3>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Box width={"100%"}>
                                    <video style={{width: "100%", height: "auto", borderRadius: 10}} poster={v2} controls>
                                      <source src={video} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                    <Box mt={1} className="flex-start">
                                        <div className="avatar-container">
                                          <img src={mentor1} className="image-avatar" alt="" />
                                        </div>
                                          <h3 className="mentor-name">Jennifer Lopez</h3>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Box width={"100%"}>
                                    <video style={{width: "100%", height: "auto", borderRadius: 10}} poster={v3} controls>
                                      <source src={video} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                    <Box mt={1} className="flex-start">
                                        <div className="avatar-container">
                                          <img src={mentor1} className="image-avatar" alt="" />
                                        </div>
                                          <h3 className="mentor-name">Stellina Parker</h3>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                          </Box>
                    </Box>
                  </Box>
            </Box>
        </Container>
        </div>
    );
  }
}