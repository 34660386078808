import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
  Chip,
  Divider,
  Grid,
  TextField
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from "@material-ui/icons/Add";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import InboxIcon from "@material-ui/icons/Inbox";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { avatar, elogo, bag } from "./assets";
import "./CustomisableUserProfiles.css"

// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
} from "./CustomisableUserProfilesController";
import Header from "../../../components/src/Header.web";
import { video } from "../../landingpage/src/assets";
// Custom theme begins here
const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
      color:'white',
      padding: '10px 20px'
    },
  },
});
// Custom theme ends here
const industries = [
  'Industry1',
  'Industry2',
  'Industry3',
  'Industry4',
  'Industry5',
];
const interests = [
  'UI/UX',
  'Copywriting',
  'Frontend Development',
  'Backend Development',
  'Fullstack Development',
];
// Custom style begins here
const styles = {
  button: {
    borderRadius: 10,
    padding: '15px 60px',
  },
  inputs: {
    borderRadius: 10,
  },
};
// Custom style ends here


export default class PricingAndMedia extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <div style={{backgroundColor: "rgb(250, 250, 250)", overflowX: 'hidden'}}>
        <Header  bgColor="white" />
        <Container maxWidth="lg" style={{margin: "40px auto"}}>
          <Grid container spacing={4}>
              <Grid item md={12}>
                <Paper elevation={5} classes={{ rounded: "inputRadius" }} >
                <Box fontWeight="fontWeightBold" p={5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start"
                }}>
                <Typography variant="h5" component="h2" style={{fontWeight:'bold', margin: "20px 0"}}>
                  Pricing &amp; Media
                </Typography>
                 <Grid container spacing={3}>
                    <Grid item md={6}>
                      <Typography variant="body1" style={{fontWeight:600}} component="h6">
                        Default Time
                      </Typography>
                      <TextField fullWidth className='inputRadius' variant="outlined" placeholder="15 Minutes" />
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body1" style={{fontWeight:600}} component="h6">
                          Set Cost
                        </Typography>
                        <TextField fullWidth className='inputRadius' variant="outlined" placeholder="Set Cost" />
                      </Grid>
                 </Grid>
                 <div className='divider'> </div>
                 <Box>
                    <Grid  container spacing={4}>
                    <Grid item xs={12} md={4}>
                    <input
                      accept="image/*"
                      className='file-upload'
                      id="contained-button-file"
                      multiple
                      type="file"
                    />
                    <label htmlFor="contained-button-file">
                      <Button variant="outlined" color="primary" classes={{root: 'upload-button'}}>
                        {/* <span>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                          </svg>
                        </span> */}
                        <span>
                          UPLOAD VIDEOS
                        </span>
                      </Button>
                    </label>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <video style={{width: "100%", height: "auto", borderRadius: 10}}  controls>
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <video style={{width: "100%", height: "auto", borderRadius: 10}} controls>
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </Grid>
                  </Grid>
                 </Box>
                 <Box mt={3}>
                    <Button variant="contained" color="primary" size="large" style={{...styles.button, color:'white'}} disableElevation>
                      Next
                    </Button>
                 </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        </div>
    );
  }
}
