import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Grid,
  Button,
  Input,
  Paper,
  Typography,
  Link,
  Avatar,
  CssBaseline,
  FormControlLabel,
  TextField,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MailIcon from '@material-ui/icons/Mail';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { inspidate, login} from './assets';
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: '#00aeef'
    },
    secondary: {
      main: '#4D4D4D'
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      color:'white',
      padding: '10px 20px'
    },
  },
});

const styles = {
  button: {
    borderRadius: 10,
    padding: '15px 60px',
  },
  inputs: {
    borderRadius: 10,
  },
};
// Customizable Area End

export default class EmailConfirmation extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
       // Required for all blocks
       <ThemeProvider theme={theme} >
        <Grid container component="main" style={{ height: '100vh', padding: '20px', overflowX: 'hidden', display: 'flex', alignItems: 'center',}} className={''}>
          <Grid item xs={false} sm={4} md={7} className={''}>
            <Box>
              <img src={login} alt="" style={{objectFit: 'contain', width: '100%',borderRadius: '40px'}} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={0} square style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '96%', padding: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
              <Box mb={3} >
                  <MailIcon style={{ fontSize: 84 }} color="primary" />
              </Box>
              <Box>
              <Typography component="h1" variant="h5" style={{fontWeight: 600}}>
                  Email Confirmation
              </Typography>
              </Box>
              <Box mt={3}>
                <Typography component="h3" variant="h6" style={{fontWeight: 400, marginTop: '10px', textAlign: 'center'}}>
                  A Confirmation Email has been sent to: {this.props.navigation.history.location.state.userEmail}
                </Typography>
              </Box>
              <Box my={3}>
                  <Typography component="h4" variant="body1" style={{fontWeight: 400, marginTop: '10px', textAlign: 'center'}}>
                    Click on the confirmation link in the email to activate your account. if you don't see this email you can check your junk mail folder.
                  </Typography>
              </Box>
              <Box style={{width: '100%'}}>
                <Button variant="contained" color="primary" onClick={() => { this.resendEmailConfirmationLink(this.props.navigation.history.location.state.userEmail)}} fullWidth size="large" style={{...styles.button, color:'white'}} disableElevation>
                  Resend Link
                </Button>
              </Box>
            </div>
          </Grid>
        </Grid>
       </ThemeProvider>
    );
  }

}