import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  personName: string[];
  interests: string[];
  anchorEl: any;
  open: boolean;
  mentor: any;
  selectedIndex: number;
  openExp: boolean;
  openExpEdit: boolean;
  openLogout:boolean;
  tabs: number,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  anchorEl:any;
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      personName: [],
      interests:[],
      anchorEl:null,
      open: false,
      mentor: 'no',
      selectedIndex: 0,
      openExp: false,
      openExpEdit: false,
      openLogout: false,
      tabs: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  setPersonName = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ personName: event.target.value as string[]});
  };

  handleChangeMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
    let { options } = event.target as HTMLSelectElement;
    let value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ personName: value as string[]});
  };

  setInterests = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ interests: event.target.value as string[]});
  };

  handleChangeMultipleInterests = (event: React.ChangeEvent<{ value: unknown }>) => {
    let { options } = event.target as HTMLSelectElement;
    let value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ interests: value as string[]});
  };
  
  setAnchorEl = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
      this.setState({ anchorEl: null });
  };
  handleMentorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // this.setState();
    this.setState({ mentor: event.target.value});
    // setValue((event.target as HTMLInputElement).value);
  };

   handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    this.setState({selectedIndex: index});
  };

 handleOpenExp = () => {
    this.setState({openExp: true});
  };

  handleCloseExp = () => {
    this.setState({openExp: false});
  };

  handleOpenExpEdit = () => {
    this.setState({openExpEdit: true});
  };

  handleCloseExpEdit = () => {
    this.setState({openExpEdit: false});
  };

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabs: newValue });
  };

  handleOpenLogout= ()=> {
    this.setState({openLogout: true});
  }

  handleCloseLogout= ()=> {
    this.setState({openLogout: false});
  }
  // Customizable Area End
}
