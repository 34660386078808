import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {Grid, Box, Link} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import { inspidate } from '../../blocks/landingpage/src/assets';
import './Copyright.css'

const useStyles = makeStyles({
  root: {
    minWidth: 0,
    borderRadius: 20,
    height: "100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  links: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  },
  link: {
    margin: '0 10px',
  },
  icon:{
    height: 80,
    width: 80,
    objectFit: 'contain',
    objectPosition: "center",
  }
});

export default function Copyright(props:any) {
  const classes = useStyles();
  return (
    <Grid container spacing={2}  className="copyright_box">
        <Grid item md={6}>
          <Typography variant="body1"  style={{fontWeight:400, color: '#818181'}}>
           &copy; Inspidate. Official All Rights Reserved
          </Typography>
        </Grid>
        <Grid item md={6} className="social_links_box">
            <Box className={classes.links}>
                <Link className={classes.link}>
                  <TwitterIcon />
                </Link>
                <Link className={classes.link}>
                  <FacebookIcon />
                </Link>
            </Box>
        </Grid>
    </Grid>
  );
}
