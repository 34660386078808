import React from "react";
// Customizable Area Start
import { 
  Box, 
  Button, 
  Paper, 
  Container, 
  Grid, 
  Popover, 
  Checkbox,
  TextField,
  ListItem, 
  IconButton,
  ListItemAvatar,
  DialogContent,
  MenuItem, 
  Dialog,
  Avatar, 
  ListItemText, 
  List } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { inspidate, icon1, icon2, icon3, icon4, icon5, icon6, video, videoPoster, listArrow, cta, hero } from "./assets";
import MarketPlaceCard from "../../../components/src/MarketplaceCard.web";
import Footer from "../../../components/src/Footer.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import grey from '@material-ui/core/colors/grey';
import "./BrowseMentorPage.css"
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const styles = {
  filterButtons: {
    borderRadius: "6px",
    display: "flex",
    padding: "10px 15px",
    backgroundColor: "white",
    color: "#818181",
    justifyContent: "space-between",
    width: "100%"
  },
  popover: {
    margin: "10px 0",
    padding: "10px",
  },
  // filterPanel:{
   
  // }
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const expertise = [
  { title: 'Advice on funding'},
  { title: 'Content Marketing'},
  { title: 'Product Management'},
  { title: 'Design/UX'},
  { title: 'Analytics'},
];
// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";
import { url } from "inspector";
import Copyright from "../../../components/src/Copyright.web";
import SearchBoxBrowseMentor from "../../../components/src/SearchBoxBrowseMentor.web";
import Header from "../../../components/src/Header.web";
import MentorCard from "../../../components/src/MentorCard.web";
import SearchBox from "../../../components/src/SearchBox.web";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start  
    // Customizable Area End
  }

  render() {
    return (
      <>
      {/* Customizable Area Start */}
      <div style={{overflowX: 'hidden', backgroundColor: `${grey[50]}`}} >
      <Header  bgColor="white" />
      <Box style={{boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", backgroundColor: "white"}} >
        <Container maxWidth="lg" style={{padding:24}}>
          {/* <SearchBoxBrowseMentor /> */}
          <SearchBox/>
          <Grid container spacing={10} className="mentor_filters">
            <Grid item xs={12} md>
            <Button
            aria-describedby="simple-popover" 
              variant="outlined"
              color="default"
              style={{...styles.filterButtons}}
              endIcon={<ExpandMoreIcon />}
              onClick={this.handleExpertisePopover}
            >
              Expertise
            </Button>
            <Popover
                id="simple-popover"
                style={styles.popover}
                open={Boolean(this.state.anchorEl)}
                anchorEl={this.state.anchorEl}
                onClose={this.handleExpertisePopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box p={2}>
                <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={expertise}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.title}
                      </React.Fragment>
                    )}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Checkboxes" placeholder="Favorites" />
                    )}
                  />
                </Box>
              </Popover>
            </Grid>
            <Grid item xs={12} md>
            <Button
              variant="outlined"
              color="default"
              style={{...styles.filterButtons}}
              endIcon={<ExpandMoreIcon />}
            >
              Language
            </Button>
            </Grid>
            <Grid item xs={12} md>
            <Button
              variant="outlined"
              color="default"
              style={{...styles.filterButtons}}
              endIcon={<ExpandMoreIcon />}
            >
              Industry
            </Button>
            </Grid>
            <Grid item xs={12} md>
            <Button
              variant="outlined"
              color="default"
              style={{...styles.filterButtons}}
              endIcon={<ExpandMoreIcon />}
            >
            Role
            </Button>
            </Grid>
            <Grid item xs={12} md>
            <Button
              variant="outlined"
              color="default"
              style={{...styles.filterButtons}}
              endIcon={<ExpandMoreIcon />}
            >
              Topic of Interest
            </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
     
      <Container maxWidth="lg">
          <Box width={"100%"} my={3} className="mentors_found_box">
              <Box>
               <h2 className="mentors_text">20 Mentors Found</h2>  
              </Box>
              <Box style={{display: "flex", alignItems: "center", justifyContent: "start"}}>
                <Button
                variant="outlined"
                color="default"
                aria-describedby={'sort-popover'}
                onClick={this.handleSortPopover}
                style={{  
                borderRadius: "6px",
                display: "flex",
                padding: "10px 20px",
                backgroundColor: "white",
                color: "#818181",
                justifyContent: "space-between",
                 flexShrink: 0
                }}
                endIcon={<ExpandMoreIcon />}
              >
                  Sort By: Newest
              </Button>
              <Popover 
                id="sort-popover"
                style={styles.popover}
                open={Boolean(this.state.sortAnchorEl)}
                anchorEl={this.state.sortAnchorEl}
                onClose={this.handleSortPopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }} >
                 <List component="nav" aria-label="secondary mailbox folders">
                    <ListItem button>
                      <ListItemText primary="Newest" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="Oldest" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="Ratings" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="Lowest Price" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="Highest Price" />
                    </ListItem>
                  </List>
              </Popover>
              <Button 
                onClick={this.handleClickOpenFilter}
                variant="outlined"
                color="default"
                style={{ 
                borderRadius: "6px",
                marginLeft: "10px",  
                padding: "10px 20px",
                backgroundColor: "white",
                color: "#818181", 
                flexShrink: 0}}
                startIcon={<svg xmlns="http://www.w3.org/2000/svg" style={{height:"20px", width: "20px"}} viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd" />
              </svg>}
              >
                 More Filters
              </Button>
              {/* Filter  */}
              <Dialog onClose={this.handleCloseFilter} classes={{paper: 'more_filters_paper', paperScrollPaper: 'paper_scroll_paper'}} aria-labelledby="customized-dialog-title" open={this.state.openFilter}  >
                    <DialogContent>
                    <Box className="more_filter_header">
                        <Typography variant="h5" style={{fontWeight: 600}} component="h3">
                          More Filters
                        </Typography>
                        <IconButton aria-label="Close Button" onClick={this.handleCloseFilter}>
                            <CloseIcon fontSize={'large'} />
                        </IconButton>
                    </Box>
                    <Box>
                       
                    </Box>
                    </DialogContent>
                  </Dialog>
              </Box>
          </Box>
      </Container>

      <Container maxWidth="lg">
          <MentorCard/>
          <MentorCard/>
          <MentorCard/>
      </Container>

    </div>
      {/* Customizable Area End */}
      </>
    );
  }
}