import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Box, Paper,Dialog, DialogTitle, DialogContent,FormControl,OutlinedInput, InputAdornment, TextField, RadioGroup,FormLabel,FormControlLabel, Radio, Link, Button, IconButton, InputBase} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FlagIcon from '@material-ui/icons/Flag';
import ShareIcon from '@material-ui/icons/Share';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import CloseIcon from '@material-ui/icons/Close';
import TwitterIcon from '@material-ui/icons/Twitter';
import MailIcon from '@material-ui/icons/Mail';
import { inspidate, bag, mentor1, mentor2, mentor3, calendar, facebook, instagram, linkedin, twitter } from '../../blocks/landingpage/src/assets';
import "./SearchBox.css"
import "./MentorCard.css"

const useStyles = makeStyles({
  root: {
    background: "white",
    margin: "25px 0", 
    borderRadius: 10,
    padding: "20px", 
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
  }, 
  icon: {
    height: "20px",
    width: "20px",
    flexShrink: 0,
  },
  search: {
    padding: "10px", 
    appearance: "none",
    MozAppearance: "none",
    WebkitAppearance:"none",
    flexShrink: 0,
    outline: "none",
    border: "none",
    '::placeholder': {
      fontSize: "1.25rem",
    },
  },
  button: {
    borderRadius: 6,
    color: "white",
    flexShrink: 0,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: "30px"
  },
  reviewBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start'
  },
  chip:{
    borderRadius: 6,
    marginRight: 20,
  },
  chips:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  badge:{
    borderRadius: 20,
    marginLeft: 10,
    color: "white",
    backgroundColor: "#2DA400",
    '&:hover': {
      backgroundColor: '#2DA400',
      borderColor: '#2DA400',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#2DA400',
      borderColor: '#2DA400',
    },
  },
  displayFlex:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  displayFlexCenter:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  modal: {
    padding: "50px"
  },
  ".MuiDialogContent-root:first-child":{
    paddingTop: 0
  },
  iconButton:{
    border: "1px solid rgba(0, 0, 0, 0.23)",
    margin: "15px"
  },
  twitterIcon:{
    color: "#1DA1F2",
  },
  linkedInIcon:{
    color: "#0e76a8",
  },
  mailIcon:{
    color: "#1DA1F2",
  },
  facebookIcon:{
    color: "#4267B2",
  }
});
export default function MentorCard(props:any) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [report, setReport] = React.useState(false);
  const [value, setValue] = React.useState('False Information');
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenReport = () => {
    setReport(true);
  };
  const handleCloseReport = () => {
    setReport(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={4} style={{flexWrap: 'nowrap'}}>
        <Grid item md={10} >
            <Box className='image_icon_container'>
                <img src={ mentor1 } className="mentor_image" alt="Jellina Hawking " />
                <Box>
                  <Box className={classes.chips}>
                  <h1 className="mentor_name">Jellina Hawking</h1>
                  {
                    props.forProfilePage &&  <>
                    <Button variant="contained" onClick={handleClickOpenReport} className={classes.button} style={{color:"black", background:"white"}}  startIcon={<FlagIcon />} disableElevation>
                       Report User
                     </Button>
                     <Button variant="contained" onClick={handleClickOpen} className={classes.button} style={{color:"black", background:"white"}}  startIcon={<ShareIcon />} disableElevation>
                       Share Profile
                     </Button>
                     <IconButton style={{padding: '6px'}} aria-label="Facebook">
                       <img src={facebook} alt="Facebook" />
                     </IconButton>
                     <IconButton style={{padding: '6px'}} aria-label="Instagram">
                       <img src={instagram} alt="instagram" />
                     </IconButton>
                     <IconButton style={{padding: '6px'}} aria-label="twitter">
                       <img src={twitter} alt="twitter" />
                     </IconButton>
                     <IconButton style={{padding: '6px'}} aria-label="linkedin">
                       <img src={linkedin} height={"20px"} width={'auto'} alt="linkedin" />
                     </IconButton>
                    </>
                  }
                 </Box>
                 {/* Share Profile Modal Begins */}
                 <Dialog fullWidth maxWidth={'sm'} onClose={handleClose} className={classes.modal} aria-labelledby="customized-dialog-title" open={open} >
                    <DialogContent >
                    <Box className={classes.displayFlex}>
                        <Typography variant="h5" style={{fontWeight: 600}} component="h3">
                          Share Profile
                        </Typography>
                        <IconButton aria-label="Close Button" onClick={handleClose}>
                            <CloseIcon fontSize={'large'} />
                        </IconButton>
                    </Box>
                    <Box my={2} className={classes.displayFlexCenter}>
                        <IconButton aria-label="Share Via Mail" className={classes.iconButton}>
                            <MailIcon style={{ fontSize: 40 }} className={classes.mailIcon} />
                        </IconButton>
                        <IconButton aria-label="Share on Facebook" className={classes.iconButton}>
                            <LinkedInIcon style={{ fontSize: 40 }} className={classes.linkedInIcon} />
                        </IconButton>
                        <IconButton aria-label="Share on LinkedIn" className={classes.iconButton}>
                            <FacebookIcon style={{ fontSize: 40 }} className={classes.facebookIcon} />
                        </IconButton>
                        <IconButton aria-label="Close Button" className={classes.iconButton}>
                            <TwitterIcon style={{ fontSize: 40 }} className={classes.twitterIcon} />
                        </IconButton>
                    </Box>
                    <Box mb={5}>
                      <OutlinedInput className='inputRadius' placeholder=""
                          data-test-id="txtInputPassword"
                          type={"text"}
                          readOnly={true}
                          fullWidth={true}
                          value="https://inspidate/profile/9876543210"
                          endAdornment={
                            <InputAdornment position="end">
                              <Button color={"primary"} 
                                onClick={()=> console.log("Hello")}
                              >
                                Copy Link
                              </Button>
                            </InputAdornment>
                          } />
                    </Box>
                    </DialogContent>
                  </Dialog>
                 {/* Share Profile Modal Ends */}
                  {/* Report User Section Begins */}
                  <Dialog fullWidth maxWidth={'sm'} onClose={handleCloseReport} className={classes.modal} aria-labelledby="customized-dialog-title" open={report} >
                    <DialogContent >
                    <Box className={classes.displayFlex}>
                        <Typography variant="h5" style={{fontWeight: 600}} component="h3">
                          Report User
                        </Typography>
                        <IconButton aria-label="Close Button" onClick={handleCloseReport}>
                            <CloseIcon fontSize={'large'} />
                        </IconButton>
                    </Box>
                    <Box>
                      <Box>
                      <FormControl component="fieldset">
                        <RadioGroup  name="message" value={value} onChange={handleChange}>
                          <FormControlLabel value="Scam or fraud" control={<Radio />} label="Scam or fraud" />
                          <FormControlLabel value="It's Spam" control={<Radio />} label="It's Spam" />
                          <FormControlLabel value="False Information" control={<Radio />} label="False Information" />
                        </RadioGroup>
                      </FormControl>
                      </Box>
                      <Box my={3}>
                        <TextField
                          id="outlined-multiline-static"
                          label="Other"
                          multiline
                          rows={4}
                          defaultValue=""
                          variant="outlined"
                        />
                      </Box>
                      <Box mb={3} className={classes.chips} style={{justifyContent: 'space-around'}}>
                        <Button variant="contained" color='primary' className={classes.button} style={{width: "50%"}}  size="large" disableElevation>
                        Submit
                        </Button>
                        <Button variant="outlined" color='default' className={classes.button} style={{color: 'black', width: "50%", marginRight:6}}  size="large" disableElevation>
                         Cancel
                        </Button>
                      </Box>
                    </Box>
                    </DialogContent>
                  </Dialog>
                 {/* Report User Section Ends */}
                  <Typography component="h5" variant='body1' style={{margin: "10px 0", color: "#818181"}}>
                    Google Data Studio Expert, Comp Intel Pro. Startups Acquired
                  </Typography> 
                </Box>
            </Box>
        </Grid>
        <Grid item md={2}>
            <Box ml={3}>
                <Typography component="h3" variant='h5' style={{fontWeight: 700, textAlign: "right"}}>
                  $15
                </Typography>
                <Typography component="h5" variant='body1' style={{ textAlign: "right", whiteSpace: 'nowrap'}}>
                  Per 15 mins
                </Typography> 
              </Box>
        </Grid>
      </Grid>
      <Box className="mentor_exp_avail">
        <Box className={classes.chips}>
          <img src={ calendar } className={classes.icon} alt="Calendar" />
          <Typography component="h5" variant='body1' style={{marginLeft: "10px", color: "#818181", fontWeight: 500}}>
            Next available - Friday, March 18, 2022 1:30 AM IST
          </Typography> 
        </Box>
        <Box ml={3} className={classes.chips}>
          <img src={ bag } className={classes.icon} alt="Calendar" />
          <Typography component="h5" variant='body1' style={{marginLeft: "10px", color: "#818181", fontWeight: 500}}>
              Professional Experience - 37Year
          </Typography> 
        </Box>
      </Box>
      { !props.forProfilePage &&  <><Box my={3}>
        <Typography component="h6" variant='body1' style={{ color: "#818181" }}>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptates animi blanditiis perferendis numquam hic consequatur itaque veniam, quia ipsa nisi qui adipisci iste dolores aliquid delectus quas nam dolore explicabo!
        </Typography>
      </Box> <Box my={3} className="mentor_card_chips">
            <Button
              variant="contained"
              style={{ color: "#818181" }}
              className="mentor_card_chip"
              disableElevation
            >
             Idea Validation
            </Button>
            <Button
              variant="contained"
              style={{ color: "#818181" }}
              className="mentor_card_chip"
              disableElevation
            >
             Growth Marketing
            </Button>
            <Button
              variant="contained"
              style={{ color: "#818181" }}
              className="mentor_card_chip"
              disableElevation
            >
             Sales Marketing
            </Button>
            <Button
              variant="contained"
              style={{ color: "#818181" }}
              className="mentor_card_chip"
              disableElevation
            >
             Product Analysis
            </Button>
        </Box>
         <span className="h-[1px] w-full bg-slate-300" style={{display:'block', margin: "10px 0"}}></span></> }
      <Box className="mentor_card_footer space-y-10">
     <Box className="mentor_card_primary_buttons space-y-10">
        <Button variant="contained" color='primary' className={"mentor_card_primary_button"} style={{color:"white"}}  size="large" disableElevation>
          Request a Call
        </Button>
        <Button variant="contained" color='default' className={"mentor_card_primary_button"} style={{color: "black"}} size="large" startIcon={<FavoriteIcon />} disableElevation>
          Add to favorites
        </Button>
     </Box>
        <Box className={classes.reviewBox}>
           <Typography component="h6" variant='body1'>
            4 reviews/ 5 sessions
            </Typography>
            <Button
              variant="contained"
              color="default"
              className={classes.badge}
              startIcon={<StarIcon />}
              disableElevation
            >
              4.6
            </Button>
        </Box>
      </Box>
    </Box>
  );
}
